// TEMPLATE zum Kopieren für die Erweiterung neuer Funktionen (Scripts)
(function ($) {
        /* INIT ***********************************************************************************************************/
        SITE.onInit.push(function () {
            markLabel();
            makeStars();
        });

        /* GLOBAL FUNCTIONS ***********************************************************************************************/
        SITE.funcs.globalDummyFuncCall = function () {
            markLabel();
        };

        /* PRIVATE FUNCTIONS **********************************************************************************************/

        function markLabel() {
            $('input[type="checkbox"]').click(function () {

                $this = $(this);
                $label = $this.parent();

                if ($this.prop('checked')) {
                    $label.css('color', '#ff6633');
                } else {
                    $label.css('color', '#000000');
                }

            });
        }

        function makeStars() {
            $(".menu-li-203").append("<a href='https://www.google.com/search?client=firefox-b-d&q=korehnke+kommunikation#lrd=0x47a851e56042f3b9:0x53bab4defeebc980,1,,,%22' target='_blank'><span class='socicon-reverbnation margin'></span><span class='socicon-reverbnation'></span><span class='socicon-reverbnation'></span><span class='socicon-reverbnation'></span><span class='socicon-reverbnation'></span></a>");
        }

    }

)
(window.jQuery);