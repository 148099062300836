// Funktionen für die Navigation
(function($) {
    /* INIT ***********************************************************************************************************/
    SITE.onInit.push(function() {
        adjustMenuDisplay();
    });

    /* RESIZE *********************************************************************************************************/
    SITE.onResize.push(function() {
        adjustMenuDisplay();
    });

    /* SCROLL *********************************************************************************************************/
    SITE.onScroll.push(function() {
        adjustMenuDisplay();
    });

    /* PRIVATE FUNCTIONS **********************************************************************************************/
    function adjustMenuDisplay() {
        $('#page').addClass('fade-in');
    }
})(window.jQuery);