import 'mmenu-js';
import 'mburger-css';
import PerfectScrollbar from 'perfect-scrollbar';

window.PerfectScrollbar = PerfectScrollbar;

// Plugin mmenu
(function ($) {
    /* INIT ***********************************************************************************************************/
    SITE.onInit.push(function () {
        initMenu();
        adjustWidth();
    });

    /* RESIZE *********************************************************************************************************/
    SITE.onResize.push(function () {
        adjustWidth();
    });

    /* PRIVATE FUNCTIONS **********************************************************************************************/
    function initMenu() {

        // Fire the plugin
        const menu = new Mmenu(
            "#menu-main",
            {
                // options
                "slidingSubmenus": false,
                "extensions": {
                    "all": [
                        "fx-listitems-slide"
                        , "pagedim-black"
                        //,"fx-panels-slide-up"   // Sliden des Panels nach oben
                        //,"fx-listitems-slide"   // Menuitems sliden lassen
                        //,"pagedim-black"        // Seitenhintergund ausgrauen
                        //,"multiline"          // Anzeige der Menupunkte auch mehrzeilig
                        , "position-front"       // Anzeige im Vordergrund, Menu verschiebt nicht den Content
                        , "position-right"       // Menu auf der rechten Seite
                        //,"shadow-page"          // Schatten am Rand des Menus zur Page
                        //,"theme-dark"          // Theme weiß
                        , "border-none"          // Keine Linien zwischen den Menupunkten anzeigen
                        //,"fullscreen"
                    ]
                    //,"(min-width: 600px)": ["listview-30"]
                    //,"(min-width: 900px)": ["listview-60"]
                },// TODO: Entfernen der Navbar funktioniert nicht mehr
                //navbar: { add: false },
                /*navbars: [
                    {
                        content : [ "<div class=\"menu-icon mm-btn\"><i></i><i></i><i></i></div>" ],
                        height  : 1
                    }
                ]*/
            }, {
                // configuration
                //navbars: {
                // navbars configuration
                //},
                offCanvas: {
                    //pageSelector: ".page"
                    page: {
                        selector: "#page"
                    }
                },
                classNames: {
                    selected: "current"
                }
            }
        );

        // Event registrieren, dass bei Menü-Links mit Anker das Menü schließt
        $('.mm-listitem__text').click(function () {
            if ($(this).attr("href").indexOf('#anchor') > -1) {
                menu.close();
            }
        });

        /*// Helferfunktionen zum Dimmen des Contentbereichs wenn Menü geöffnet
        $('.mburger').click(function () {
            if ($('#website').hasClass("mm-wrapper_opening")) {
                $('.dimm-background').addClass("hidden");
                menu.close();
            } else {
                $('.dimm-background').removeClass("hidden");
            }
        });

        $('.mm-wrapper__blocker').click(function () {
            if ($('#website').hasClass("mm-wrapper_opening")) {
                $('.dimm-background').removeClass("hidden");
                menu.close();
            } else {
                $('.dimm-background').addClass("hidden");
            }
        });*/

        // Scrollbar schön machen
        const ps = new PerfectScrollbar('#panel-main', {});
    }

    function adjustWidth() {
        var window = document.body.offsetWidth;
        var widthpage = 1280;
        var paddingright = (window - widthpage) / 2;
        var widthmenu = 500;
        var width = widthmenu + paddingright;
        if (document.body.offsetWidth > 1209) {
            $('.mm-menu').css('padding-right', paddingright + 'px');
            $('.mm-menu').css('max-width', width + 'px');
        }
    }
})(window.jQuery);